"use client";
import React, { createContext, useMemo, useState } from "react";

export const PopupMaintenanceContext = createContext();
export const passShowPopup = { setShowPopup: (x) => x };

const PopupMaintenanceProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);
  passShowPopup.setShowPopup = setShowPopup;

  const contextValue = useMemo(
    () => ({ showPopup, setShowPopup }),
    [showPopup, setShowPopup]
  );

  return (
    <PopupMaintenanceContext.Provider value={contextValue}>
      {children}
    </PopupMaintenanceContext.Provider>
  );
};

export default PopupMaintenanceProvider;
